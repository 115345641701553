@use './variables' as variables;
@use '@material/button/mixins' as mdc-button-mixins;

.hero {
  padding-top: 88px;
  padding-bottom: 64px;
  width: 100%;
  // background-color: #005a9c;
  background-color: variables.$primary-dark;
  @media (min-width: variables.$breakpoints-desktop-min) {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
.hero__button {
  @include mdc-button-mixins.filled-accessible(variables.$secondary);
  margin-top: 32px;
}
.hero__content {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  color: white;
  text-align: center;
}
.hero__highlight {
  text-decoration: underline;
}
