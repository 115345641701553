@use '../../variables' as variables;
@use '@material/typography/mixins' as typography-mixins;

.page {
  padding: 32px;
  @media (max-width: variables.$breakpoints-tablet-max) {
    padding: variables.$margin-tablet;
  }
}
.page__section {
  margin-top: 32px;
  @media (max-width: variables.$breakpoints-tablet-max) {
    margin-top: variables.$margin-tablet;
  }
}
.page__headline {
  @include typography-mixins.typography(headline3);
}
.page__description {
  @include typography-mixins.typography(subtitle1);
}
.page__subtitle {
  @include typography-mixins.typography(headline4);
}
.page__subtitle + .variant {
  margin-top: 16px;
}
