$background: #f6f8fa;
$foreground: #24292e;
$blue: #032f62;
$light-blue: #005cc5;
$gray: #6a737d;
$green: #22863a;
$orange: #e36209;
$purple: #6f42c1;
$red: #d73a49;

.copy {
  position: absolute;
  top: 4px;
  right: 2px;
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }
}

.code {
  pre.hljs {
    border-radius: 4px;
    margin: 0;
    padding: 16px;
    background-color: $background;
    color: $foreground;
    position: relative;
    overflow: auto;
    code {
      font-family: Menlo, monospace;
      font-size: 0.875rem;
      line-height: 1;
      tab-size: 2;
    }
  }
  &.lang-scss {
    .keyword {
      color: $red;
    }
    .string {
      color: $blue;
    }
    .variable {
      color: $orange;
    }
    .function,
    .mixin {
      color: $purple;
    }
  }
  &.lang-jsx {
    .comment {
      color: $gray;
    }
    .keyword,
    .keyword.module {
      color: $red;
    }
    .string {
      color: $blue;
    }
    .function,
    .function .maybe-class-name,
    .number {
      color: $purple;
    }
    .boolean {
      color: $light-blue;
    }
    .tag {
      .tag {
        color: $green;
        .punctuation {
          color: $foreground;
        }
        .class-name {
          color: $green;
        }
      }
      .script {
        .script-punctuation {
          color: $red;
        }
      }
    }
    .attr-name {
      color: $purple;
    }
    .attr-value {
      color: $blue;
      .punctuation {
        &:first-child {
          color: $red;
        }
      }
    }
  }
}
