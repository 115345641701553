@use 'sass:map';
@use '../../variables' as variables;
@use '@material/theme/color-palette.scss' as theme-color-palette;
@use '@material/theme/variables.scss' as theme-variables;
@use '@material/typography/mixins' as typography-mixins;
@use '@material/circular-progress/mixins' as circular-progress-mixins;

.home {
  .page {
    padding: 0;
  }
  .page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page__section {
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
  }
  .page__subtitle {
    text-align: center;
  }
  .iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
}
.home__card {
  width: 100%;
  .primary-title {
    padding: 16px;
    text-align: center;
  }
  .title {
    @include typography-mixins.typography(headline6);
  }
  .subtitle,
  .supporting-text {
    @include typography-mixins.typography(body2);
    color: map.get(
      theme-variables.$property-values,
      text-secondary-on-background
    );
  }
  .rich-media__content {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    .primary-title {
      text-align: initial;
    }
    .grid {
      background: rgba(0, 0, 0, 0.2);
      height: 100%;
    }
    .grid-inner {
      height: 100%;
    }
    .grid-cell {
      background: rgba(0, 0, 0, 0.2);
      height: 153px;
      @media (max-width: variables.$breakpoints-tablet-max) {
        height: 56px;
      }
    }
    .menu.mdc-menu-surface {
      position: relative;
    }
    .switch {
      .mdc-switch__native-control {
        pointer-events: none;
      }
    }
  }
}
