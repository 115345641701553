@use '@material/typography/mixins' as typography-mixins;

.usage {
  padding: 16px;
}
.usage__title {
  @include typography-mixins.typography(subtitle1);
  margin-top: 16px;
  font-weight: 500;
  &:first-child {
    margin-top: 0;
  }
}
.usage__subtitle {
  @include typography-mixins.typography(subtitle2);
  margin-top: 8px;
}
