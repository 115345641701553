@use './variables' as variables;
@use '@material/button/mixins' as mdc-button-mixins;
@use '@material/ripple/mixins' as mdc-ripple-mixins;

body {
  margin: 0;
  * {
    box-sizing: border-box;
  }
}
.app-bar__title {
  padding-left: 4px;
  color: white;
}
.drawer {
  @media (min-width: variables.$breakpoints-desktop-min) {
    position: fixed;
    z-index: 3;
  }
}
.drawer__header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 16px;
}
.drawer__title {
  margin: 0;
}
.drawer-app-content {
  padding-left: 256px;
  @media (max-width: variables.$breakpoints-tablet-max) {
    padding-left: initial;
    z-index: 2;
  }
}
.inline-code {
  color: #24292e;
}
.mdc-snackbar__action {
  @include mdc-button-mixins.ink-color(variables.$primary-light);
  @include mdc-ripple-mixins.states(variables.$primary-light);
}
