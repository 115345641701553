@use '@material/typography/mixins' as typography-mixins;

.props {
  margin-top: 24px;
  blockquote {
    padding: 8px 16px;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin: 16px 0;
  }
}
.props__headline {
  @include typography-mixins.typography(headline6);
}
.props__table {
  display: block;
  margin-top: 8px;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
  tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }
  tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
  th {
    @include typography-mixins.typography(subtitle1);
    font-weight: 500;
  }
  th,
  td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }
}
