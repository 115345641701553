@use 'sass:map';
@use '@material/theme/color-palette.scss' as theme-color-palette;
@use '@material/theme/variables.scss' as theme-variables;
@use '@material/typography/mixins' as typography-mixins;
@use '@material/circular-progress/mixins' as circular-progress-mixins;

.demo {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}
.demo-item {
  margin: 8px;
  .mdc-tab-indicator .mdc-tab-indicator__content--icon {
    color: rgba(0, 0, 0, 0.38);
  }
}
.demo-item--fullwidth {
  width: 100%;
}
.demo-item--card {
  max-width: 344px;
  .header {
    display: flex;
    align-items: center;
  }
  .thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: url('../../assets/media.svg') no-repeat center;
    background-size: cover;
    margin-left: 16px;
  }
  .primary-title,
  .supporting-text {
    padding: 16px;
  }
  .primary-title + .supporting-text {
    padding-top: 0;
  }
  .title {
    @include typography-mixins.typography(headline6);
  }
  .subtitle,
  .supporting-text {
    @include typography-mixins.typography(body2);
    color: map.get(
      theme-variables.$property-values,
      text-secondary-on-background
    );
  }
  .rich-media {
    background: url('../../assets/media.svg');
  }
  .rich-media__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .title,
    .subtitle {
      color: white;
    }
  }
}
.demo-item--chips {
  .input-chips {
    @include typography-mixins.typography(body2);
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .input-chips__chip-set {
    flex-grow: 1;
  }
  .input-chips__input {
    @include typography-mixins.typography(body2);
    border-width: 0;
    outline: 0;
    flex-grow: 1;
  }
}
$colors: (purple, pink, teal, gray);
#custom-four-colors .demo-circular-progress {
  .mdc-circular-progress {
    @include circular-progress-mixins.indeterminate-colors($colors);
  }
}
.demo-item--grid {
  .grid {
    background: rgba(0, 0, 0, 0.2);
    min-width: 360px;
    &--regular {
      max-width: 800px;
    }
    &--cell-alignment {
      min-height: 200px;
    }
  }
  .grid-inner {
    min-height: 200px;
  }
  .grid-cell {
    background: rgba(0, 0, 0, 0.2);
    height: 100px;
    &--alignment {
      max-height: 50px;
    }
  }
}
#right-click-demo {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.demo-item--menu {
  height: 112px;
}
.demo-item--right-click {
  width: 100%;
}
.demo-item--bottom {
  height: 148px;
}
.demo-item--switch {
  margin: 19px;
}
.demo-item--tabs {
  .mdc-tab-scroller__scroll-area--scroll {
    overflow-x: hidden;
  }
}
