@use 'sass:map';
@use '@material/theme/variables.scss' as theme-variables;
@use '@material/typography/mixins' as typography-mixins;

.variant__headline {
  @include typography-mixins.typography(headline6);
}
.variant__description {
  @include typography-mixins.typography(body2);
  color: map.get(
    theme-variables.$property-values,
    text-secondary-on-background
  );
  ul,
  li {
    margin: 0;
  }
  ul {
    padding-left: 32px;
  }
}
