@use 'sass:map';
@use '@material/layout-grid/variables' as grid-variables;

$breakpoints-desktop-min: map.get(grid-variables.$breakpoints, desktop);
$breakpoints-tablet-max: $breakpoints-desktop-min - 1px;
$breakpoints-tablet-min: map.get(grid-variables.$breakpoints, tablet);
$breakpoints-phone-max: $breakpoints-tablet-min - 1px;
$breakpoints-phone-min: 375px;
$breakpoints-phone-small-max: 374px;

$margin-desktop: map.get(grid-variables.$default-margin, desktop);
$margin-tablet: map.get(grid-variables.$default-margin, tablet);
$margin-phone: map.get(grid-variables.$default-margin, phone);

$primary: #1e90ff;
$primary-dark: #0b5fb3;
$primary-light: #91cfff;
$secondary: #ffab1f;
$secondary-dark: #b37000;
$secondary-light: #fff69b;
